import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, finalize, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { User } from '../models/user';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private destroy$ = new Subject<void>();
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  currentUser$: Observable<User | null>;
  currentUserSubject: BehaviorSubject<User | null>;

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient, private router: Router) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.currentUserSubject = new BehaviorSubject<User | null>(null);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.getUserByToken().pipe(takeUntil(this.destroy$)).subscribe();
  }

  get currentUserValue(): User {
    return this.currentUserSubject.value!;
  }

  set currentUserValue(profile: User) {
    this.currentUserSubject.next(profile);
  }

  getUserByToken(): Observable<User> {
    this.isLoadingSubject.next(true);
    return of({
      schoolId: 1031260847,
      firstName: 'สุรศักดิ์',
      lastName: 'หาญลำยวง',
      email: 'surasak.hanl@gmail.com'
    } as User)
      .pipe(
        map((profile: User) => {
          if (profile) {
            this.currentUserSubject = new BehaviorSubject<User | null>(profile);
          } else {
            this.logout();
          }
          return profile;
        }),
        finalize(() => this.isLoadingSubject.next(false)));
  }

  logout() {
    localStorage.clear();
    console.log("Clear Local Storage");
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}